.button-file {
  font-size: 20px;
  padding: 20px;
  /* width: 145px; */
  background-color: #c4c4c4;
  color: rgba(34, 34, 34, 1);
  text-align: center;
  margin: auto 0;
  border-radius: 50%;
  display: block;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}

.radio-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  width: 100%;
}
.custom-radio-wrapper {
  display: flex;
  align-items: center;
}

.radio-custom-support label {
  font-weight: 400;
  margin-bottom: 15px;
}

.modal {
  &__main-container {
    padding: 50px 15px 0px 15px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 5px;
    max-height: 580px;
    overflow: auto;
    margin: 0px 40px 30px;
  }
  &__header-container {
    display: flex;
  }
  &__title {
    font-weight: 700;
    font-size: 28px;
    padding-bottom: 10px;
  }
  &__confirmation-title {
    color: #222222;
    margin-top: 10px;

    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
  }

  .modal__with-container {
    padding: 0 !important;
  }

  .modal__forter-border-top {
    border-top: 1px solid #e6e6e6;
  }
}
.modal-content {
  background-color: #f8f8f8;
  height: 100%;
}
.exclude-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  border: none;
  border-radius: 5px;
}
.modal__confirmation-text {
  margin-top: 2px;
  text-align: center;
}

.confirmation-modal {
  margin: 11rem auto;
}

.container-with-buttom {
  width: 100%;
}

.card__main-container {
  margin: 0px 0px 0px 0px !important;
}

// media query for mobile container
@media (max-width: $media-query-sm) {
  .mainContainer,
  .subpdv-main-container {
    padding: 20px !important;
  }
}

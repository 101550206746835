html {
  display: block;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgb(177, 177, 177);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(139, 139, 139);
    border-radius: 10px;
  }
}

button {
  .header-sandwich {
    display: none;
  }
  .header-sandwichactive {
    color: #0c0201;
    display: block;
    position: fixed;
    left: 20px;
    top: 1.5rem;
    transform: scale(1.2);
    z-index: 1;
    &:hover {
      color: #d8231b;
      transition: 0.4s;
    }
  }
  .sandwich-menu {
    color: #0c0201;
    margin: 1rem 0 0 20px;
    &:hover {
      color: #d8231b;
      transition: 0.4s;
    }
  }
}
// .page-bodymobile {
//   display: flex;
// }

.sidebar-container {
  // background-color: #ffffff;
  min-height: 100%;
  max-height: 100%;
  width: 280px;
  min-width: 280px;
  box-sizing: border-box;
  overflow-y: auto;
  box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.05);
  scroll-behavior: smooth;
  transition: transform 0.15s linear;
  transform: scaleX(100%);
  transform-origin: left;
  position: fixed;
  z-index: 100;
  background-color: #ffffff;
  top: 0;
  &::-webkit-scrollbar {
    width: 5px;
    display: block;
  }
  &::-webkit-scrollbar-track {
    background-color: rgb(200, 200, 200);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(170, 170, 170);
    border-radius: 10px;
  }
  .list-container {
    margin-left: 1rem;
    padding-bottom: 30px;
  }

  img {
    display: block;
    margin: auto;
    width: 50%;
    padding: 30px 0;
  }
  .sidebar-list {
    list-style: none;
    width: 200px;
    font-weight: 500;
    margin-bottom: 6px;
    .submenu-container {
      width: fit-content;
      .sidebar-submenu {
        transform-origin: top;
        transition: transform 0.2s linear;
        transform: scaleY(1);
        overflow: hidden;
        display: block;
        height: auto;
        li {
          margin-left: 3rem;
          a {
            color: #828282;
            padding: 0 0;
            margin: 1.3rem 0;
            &:hover {
              color: #d8231b;
              background-color: #ffffff;
              transition: 0.4s;
              span {
                font-family: 'Poppins', sans-serif;
                color: #d8231b;
                align-items: center;
              }
            }

            &.active {
              color: #d8231b;
              background-color: #ffffff !important;
              span {
                color: #d8231b;
              }
            }
          }
        }
      }

      .sidebar-submenu-collapsed {
        display: none;
        // transform-origin: top;
        // transition: transform 0.2s linear;
        // transform: scaleY(0);
        overflow: hidden;
        height: auto;
        li {
          margin-left: 3rem;
          a {
            color: #828282;
            padding: 0 0;
            margin: 1.3rem 0;
            &:hover {
              color: #d8231b;
              background-color: #ffffff;
              transition: 0.4s;
              span {
                font-family: 'Poppins', sans-serif;
                color: #d8231b;
                align-items: center;
              }
            }
          }
        }
      }
    }

    // a {
    //   .active {
    //     border: none;
    //     border-radius: 20px;
    //     background-color: rgba(216, 35, 27, 0.05);
    //     transition: 0.4s;
    //     color: #d8231b;
    //   }
    // }

    a {
      text-decoration: none;
      color: #0c0201;
      height: fit-content;
      width: fit-content;
      min-width: 170px;
      display: flex;
      padding: 8px 18px 8px 5px;
      &:hover {
        border: none;
        border-radius: 20px;
        background-color: rgba(216, 35, 27, 0.05);
        transition: 0.4s;
        span {
          font-family: 'Poppins', sans-serif;
          color: #d8231b;
          align-items: center;
        }
        svg {
          color: #d8231b;
        }
      }
      svg {
        display: block;
        margin-top: 1px;
        width: 44px;
        height: 24px;
        padding: 0 10px;
        transition: all ease-in-out 0.2s;
      }
      span {
        font-family: 'Poppins', sans-serif;
        margin-top: 2px;
        &:hover {
          transition: 0.3s;
          color: #d8231b;
        }
      }

      &.active {
        border: none;
        border-radius: 20px;
        background-color: rgba(216, 35, 27, 0.05);
        transition: 0.4s;
        span {
          color: #d8231b;
        }
        svg {
          color: #d8231b;
        }
      }
    }
  }

  .adm-container {
    .icon-chevron {
      transform: scale(0.8);
    }
  }
}
.mobile {
  display: block;
  transform-origin: left;
  transition: transform 0.15s linear;
  transform: scaleX(0%);
  position: fixed;
}

.logout-container {
  margin-bottom: 30px;
  margin-left: 18px;
  .sidebar-logout {
    list-style: none;
    width: 200px;
    font-weight: 500;
    z-index: 100;
    border-top: solid 1px #f1f1f1;
    a {
      text-decoration: none;
      color: #0c0201;
      height: fit-content;
      display: flex;
      padding: 8px 18px 8px 5px;
      &:hover {
        transition: 0.4s;
        span {
          font-family: 'Poppins', sans-serif;
          color: #d8231b;
          align-items: center;
        }
        svg {
          color: #d8231b;
        }
      }
      svg {
        display: block;
        margin-top: 3px;
        width: 44px;
        height: 24px;
        padding: 0 10px;
      }
      span {
        font-family: 'Poppins', sans-serif;
        margin-top: 6px;
        &:hover {
          transition: 0.3s;
          color: #d8231b;
        }
      }
    }
  }
}
.subactive {
  span {
    color: #d8231b;
  }
}

@media screen and (min-width: 990px) {
  .sandwich-menu {
    display: none;
  }
  .sidebar-container .mobile {
    display: none;
  }
}

.circle-grey {
  background-color: #e6e6e6;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px !important;
  transition: all 0.3s ease-in-out;
}

.footerClass {
  margin-left: 280px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 990px) {
    margin-left: 0px;
    transition: all 0.3s ease-in-out;
  }
}

.main-chat {
  /* padding: 20px; */
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.container-chat {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  width: 800px;
  height: 600px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
}

.container-user {
  background-color: #fff;
  width: 400px;
  display: flex;
  flex-direction: column;
  height: 600px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 16px;
}

.messages-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  margin-bottom: 16px;
}

.message {
}

.sender-user {
  background-color: #c3e0e5;
  color: #000;
  align-self: flex-end;
}

.sender-other {
  background-color: #77787a;
  color: #fff;
  align-self: flex-start;
}

.flex-container {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

.input-text {
  flex: 1;
  padding: 8px;
  font-size: 1.125rem;
  margin-right: 8px;
  width: 600px;
}

.button-send {
  background-color: #2d82d2;
  color: #fff;
  border-radius: 50%;
  padding: 20px;
  height: 60px;
  text-decoration: none;
  margin-top: 10px;
}

.button-send:hover {
  background-color: #184f8c;
}

.icon {
  font-size: 20px;
}

.message {
  position: relative;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  max-width: 400px;
}
.message img {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.sender-user {
  background-color: #c3e0e5;
}

.sender-other {
  background-color: #77787a;
}

.message::before,
.message::after {
  content: '';
  position: absolute;
  top: 10px;
  border-style: solid;
  border-width: 10px;
  border-color: transparent;
}

.sender-user::before {
  right: -20px;
  border-left-color: #c3e0e5;
}

.sender-other::before {
  left: -20px;
  border-right-color: #77787a;
}

.client-info {
  padding: 16px;
}

.client-info h2 {
  margin-bottom: 16px;
  font-size: 1.5rem;
  color: #333;
}

.client-info-item {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #d1d5db;
}

.label-chat {
  font-weight: bold;
  color: #333;
}

.value {
  color: #555;
}

.value-id {
  color: #555;
  font-size: 13px;
}

.clearImage {
  position: absolute;
  top: 27px;
  right: 20px;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 10;
  color: #b10303;
  font-size: 20px;
}

.update-button {
  background-color: green;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  font-size: 24px;
  margin-top: 10%;
  margin-left: 10px;
}

.update-button:hover {
  background-color: darkgreen;
}

.status-container {
  display: flex;
  padding-top: 30px;
}

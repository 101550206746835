.father-cards {
  display: flex;
  justify-content: space-between;
  width: 615px;
  min-width: 615px;
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;

    color: #222222 !important;
    width: 182px;
    min-width: 182px;
    height: 177px;
    min-height: 177px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
    border-radius: 10px;

    cursor: pointer;
    &:hover {
      border: 1px solid #f1f1f1;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
    }
    &:active {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    }
  }
}

@media (max-width: 650px) {
  .father-cards {
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    div {
      width: 100%;
      min-width: 100%;
      margin-bottom: 30px;
    }
    a {
      transition: linear 0.3s;
      margin-bottom: 27px;
    }
  }
}

.image-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-right: 10px;
}

.sectionTitle {
  font-size: 24px;
  color: #888888;
}

@import 'utils/variables';

:root {
  --theme-deafult: #d8413a;
  --theme-secondary: #d8231b;
}

// *** base ***
@import 'base/reset';
@import 'base/typography';

@import 'components/buttons';
@import 'components/switch';
@import 'components/loader';
@import 'components/icons';
@import 'components/Pdv';
@import 'components/pagination';
@import 'components/modal';
@import 'components/input';

@import 'pages/login';

// *** layout ***

@import 'layout/footer';
@import 'layout/grid';
@import 'layout/header';
@import 'layout/sidebar';

.subTitleMain {
    .rdt_TableHead {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        color: rgba(0,0,0,0.87);
        font-size: 12px;
        font-weight: 500;
        border-bottom: 1px solid;
        border-color: #E6E6E6;
    }
}
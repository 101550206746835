svg.svg-icon {
  color: #828282;
  fill: #828282;
  &:hover {
    color: #222222;
    fill: #222222;
  }
  &--active {
    color: #d8413a;
    fill: #d8413a;
  }
}
svg.last-child-icon {
  margin-right: 0px !important;
}

svg.svg-icon-trash {
  &:hover {
    color: #d8413a !important;
    fill: #d8413a !important;
  }
}

svg.svg-icon-error {
    color: #d8413a !important;
    fill: #d8413a !important;

}

.green-icon {
  color: green;
  font-size: 20px;
}

.red-icon {
  color: gray;
  font-size: 20px;
}
.statusContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.statusBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 23%;
  height: 100px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 5px;
}

.statusBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.statusTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.statusValue {
  background-color: #dfdfdf;
  margin: 5px;
  padding: 0px 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.statusIcon {
  font-size: 15px;
  margin-right: 5px;
}

.statusText {
  font-size: 12px;
  color: #333;
  margin: 0;
}

.statusBox:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.statusIcon {
  font-size: 20px;
  color: #4caf50;
  margin-right: 15px;
}

.statusText {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

@media (max-width: 1024px) {
  .statusBox {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .statusBox {
    width: 100%;
  }
}

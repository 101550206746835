.input-component {
  margin-bottom: 50px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
  width: 20px;
  height: 20px;
  border: 1.32692px solid #c4c4c4;
  accent-color: rgba(216, 65, 58, 1);
  border-radius: 10px;
}

.checkbox-list-label {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  width: 200px;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
}
.checkbox-list-name {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  width: 100%;
  color: #828282;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
}
.checkbox-list {
  display: flex;
  align-items: center;
  vertical-align: baseline;
  margin-right: 30px;
  margin-top: 15px;
}

.w-input-sm {
  width: 135px;
}

.w-input-md {
  width: 232px;
}

.addon-input {
  position: absolute;
  top: 0;
  right: 0;
  padding: 19px 20px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #828282;
}

.input-file-default-custom {
  padding: 16.75px;
  background: #e6e6e6;
  border-radius: 5px;
}

.button-wrap input[type='file'] {
  position: absolute;
  top: 6px;
  left: 0px;
  font-size: 15px;
  color: rgb(153, 153, 153);
}

.new-button {
  display: inline-block;
  padding: 14px 23px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #c4c4c4;
  color: #222222;
  position: absolute;
  z-index: 1;
  top: 15px;
  left: 13px;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  &:hover {
    background-color: #A5A5A5;
  }
}

.button-wrap {
  position: relative;
}

@media screen and (max-width: 1200px) {
  .tbody-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 990px) {
  .checkbox-list-name {
    width: 150px;
  }
}

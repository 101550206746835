.pagination-change-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #222222;
}
.select-label-container {
  margin-top: -30px;
}
@media screen and (max-width: 990px) {
  .select-label-container {
    width: 100%;
    margin-top: -18px;
    font-size: 12px;
    label {
      margin-bottom: 0;
    }
  }
}
